import {
  container,
  main,
  mainRaised,
  mlAuto,
  mrAuto,
  textCenter,
  title,
  whiteColor,
} from "assets/jss/material-kit-pro-react.jsx"

const schedulePageStyle = (theme) => ({
  main: {
    ...main,
    ...mainRaised,
    margin: "-50px 30px 100px",
    [theme.breakpoints.down("sm")]: {
      margin: "0px 0px 0px",
    },
  },
  mrAuto,
  mlAuto,
  container: {
    ...container,
    zIndex: "2",
    position: "relative",
  },
  title: {
    ...title,
    "&, & + h4": {
      color: whiteColor,
    },
  },
  textCenter: {
    textAlign: "center",
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block",
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto",
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0",
  },
  left: {
    float: "left!important",
    display: "block",
  },
  message: {
    color: whiteColor,
    "@media (max-width: 700px) and (max-height: 325px)": {
      fontSize: "1.15rem",
    },
    "@media (max-height: 374px)": {
      marginTop: "0px",
    },
    "@media (max-width: 374px)": {
      fontSize: "1.25rem",
    },
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right",
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative",
  },
  pageTitle: {
    ...title,
    ...textCenter,
    color: whiteColor,
    [theme.breakpoints.down("sm")]: {
      fontSize: "3rem",
    },
    "@media (max-width: 374px)": {
      fontSize: "2.75rem",
    },
  },
})

export default schedulePageStyle
