// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import schedulePageStyle from "assets/jss/material-kit-pro-react/views/schedulePageStyle.jsx"
// nodejs library that concatenates classes
import classNames from "classnames"
// core components
import CustomFooter from "components/Footer/CustomFooter.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import CustomHeader from "components/Header/CustomHeader.jsx"
import ReactParallax from "components/Parallax/Parallax.jsx"
import SEOHeader from "components/SEOHeader/SEOHeader.jsx"
// gatsby libraries
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import SectionTimer from "../LandingPage/Sections/SectionTimer.jsx"
// sections for this page
import ScheduleCalendar from "./Sections/ScheduleCalendar.jsx"

const useStyles = makeStyles(schedulePageStyle)

export default function SchedulePage() {
  const { scheduleBackground } = useStaticQuery(graphql`
    query getSchedulePageImages {
      scheduleBackground: file(relativePath: { eq: "banners/calendar.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  const classes = useStyles()
  return (
    <div>
      <SEOHeader
        title="Schedule - Chilimania"
        description="Calendar of all upcoming Chilimania events"
      />
      <CustomHeader />
      <ReactParallax
        imageData={scheduleBackground.childImageSharp.gatsbyImageData}
        imageTitle={"About Banner"}
        filter="dark"
        imageStyle={{ height: "75vh" }}
        small
      >
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.pageTitle}>Upcoming Chilimania Events</h1>
              <h3 className={classes.message}>
                Calendar with all Chilimania events
              </h3>
            </GridItem>
          </GridContainer>
        </div>
      </ReactParallax>
      <div className={classes.main}>
        <ScheduleCalendar />
      </div>
      <SectionTimer />
      <CustomFooter />
    </div>
  )
}
